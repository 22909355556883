<template>
  <div id="app">
    <FileList />
  </div>
</template>

<script>
import FileList from "./components/FileList.vue";

export default {
  name: "App",
  components: {
    FileList,
  },
};
</script>

<style>
#app {
  margin: 20px;
}
</style>

