<template>
  <div class="file-list">
    <h1>文件列表</h1>
    <ul>
      <li v-for="file in files" :key="file.name">
        <img :src="getIcon(file.type)" alt="icon" class="file-icon" />
        <span class="file-name">{{ file.name }}</span>
        <button @click="previewFile(file)">预览</button>
        <button @click="downloadFile(file)">下载</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "FileList",
  data() {
    return {
      files: [
        { name: "04云闪智库.gif", type: "GIF" },
        { name: "18云闪智库.gif", type: "GIF" },
        { name: "10840.pdf", type: "PDF" },
        { name: "解析pdf的元素位置.py", type: "Python" },
        { name: "进度条测试.html", type: "HTML" },
      ],
    };
  },
  methods: {
    getIcon(type) {
      const icons = {
        PDF: "https://via.placeholder.com/24?text=PDF",
        GIF: "https://via.placeholder.com/24?text=GIF",
        Python: "https://via.placeholder.com/24?text=PY",
        HTML: "https://via.placeholder.com/24?text=HTML",
      };
      return icons[type] || "https://via.placeholder.com/24?text=FILE";
    },
    previewFile(file) {
      const url = `/files/${file.name}`;
      window.open(url, "_blank");
    },
    downloadFile(file) {
      const url = `/files/${file.name}`;
      const a = document.createElement("a");
      a.href = url;
      a.download = file.name;
      a.click();
    },
  },
};
</script>

<style scoped>
.file-list {
  padding: 20px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.file-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.file-name {
  flex: 1;
}
button {
  margin-left: 10px;
  padding: 5px 10px;
}
</style>

